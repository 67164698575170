import { registerLocaleData } from '@angular/common';
import { provideHttpClient } from '@angular/common/http';
import localeEN from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl';
import {
  ApplicationConfig,
  DEFAULT_CURRENCY_CODE,
  importProvidersFrom,
  isDevMode,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';

import { CamPwaService, PWA_CONFIG_KEY } from '@camelot/capacitor';
import { CamNotificationProvider } from '@camelot/notification';
import { CamServerModule, GRAPHQL_SERVER_CONFIG, SERVER_CONFIG_KEY, TENANT_CONFIG_TOKEN } from '@camelot/server';
import { CamTranslationModule, TRANSLATION_CONFIG } from '@camelot/translation';
import { CamAuth0Module, CamUserModule } from '@camelot/user';
import { APPLICATION_CONFIG, LOCAL } from '@camelot/utils';

import { environment } from '../environments/environment';
import { routes } from './app.routes';

registerLocaleData(localeFr, 'fr');
registerLocaleData(localeEN, 'en');
registerLocaleData(localeNl, 'nl');
registerLocaleData(localeEs, 'es');

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true, ignoreChangesOutsideZone: false }),
    provideHttpClient(),
    provideAnimations(),
    provideRouter(routes),
    importProvidersFrom(CamServerModule.forRoot({ config: environment.GRAPHQL_SERVER_CONFIG })),
    importProvidersFrom(CamTranslationModule.forRoot()),
    importProvidersFrom(CamAuth0Module.forRoot(environment.AUTH0 as any)),
    importProvidersFrom(CamNotificationProvider.forRoot()),
    importProvidersFrom(CamUserModule.forRoot()),
    {
      provide: TENANT_CONFIG_TOKEN,
      useValue: { tenantId: environment.TENANT_ID },
    },
    {
      provide: PWA_CONFIG_KEY,
      useValue: { active: environment.PWAACTIVE },
    },
    {
      provide: TRANSLATION_CONFIG,
      useValue: {
        default: environment.TRANSLATION.DEFAULT,
        supportedLanguages: environment.TRANSLATION.SUPPORTEDLANGUAGES,
      },
    },
    {
      provide: LOCAL,
      useValue: { isLocal: environment.LOCAL },
    },
    {
      provide: APPLICATION_CONFIG,
      useValue: { isCustomerApplication: false },
    },
    {
      provide: SERVER_CONFIG_KEY,
      useValue: { pendindRequestId: environment.PENDINGREQUESTAPI, serverUrl: environment.SERVER_URL },
    },
    {
      provide: GRAPHQL_SERVER_CONFIG,
      useValue: { config: environment },
    },
    {
      provide: GRAPHQL_SERVER_CONFIG,
      useValue: { config: environment.GRAPHQL_SERVER_CONFIG },
    },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    CamPwaService,
    provideServiceWorker('ngsw-worker.js', {
      enabled: environment.PWAACTIVE,
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
};
