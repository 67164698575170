import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';

import { Observable, map } from 'rxjs';

import { CamLayoutModule, CamUiModule, UserLogoNaming } from '@camelot/ui';

import { environment } from 'src/environments/environment';

import { BasePage } from '../../core/abstract/basePage';
import { LayoutContentComponent } from '../../core/layout/layout-content/layout-content.component';
import { MainMenuComponent } from '../../core/menu/main-menu/main-menu.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  standalone: true,
  imports: [AsyncPipe, LayoutContentComponent, CamUiModule, CamLayoutModule, MainMenuComponent],
})
export class HomePage extends BasePage {
  get version() {
    return environment.version;
  }

  get profileName$(): Observable<{
    profilePictureUrl?: string;
    naming: UserLogoNaming | null;
  }> {
    return this._usersService.currentUser.get$().pipe(
      map(user => ({
        profilePictureUrl: user?.picture,
        naming: {
          firstName: user?.firstName || '',
          name: user?.lastName || '',
          trigram: user?.trigram || '',
        },
      }))
    );
  }
}
