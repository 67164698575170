import { Routes } from '@angular/router';

import { CamRoutes } from '@camelot/menu';
import { FeatureGuard } from '@camelot/user';

export enum ESettingsRoute {
  settings = 'settings',
  teams = 'teams',
  chift = 'chift',
  taskTypes = 'taskTypes',
  view = 'view',
}

CamRoutes.addRoute({
  key: ESettingsRoute.settings,
  url: 'settings',
  children: [
    {
      key: ESettingsRoute.teams,
      url: 'teams',
    },
    {
      key: ESettingsRoute.chift,
      url: 'integrations',
    },
    {
      key: ESettingsRoute.taskTypes,
      url: 'taskTypes',
    },
    {
      key: ESettingsRoute.view,
      url: 'integrations/:id',
    },
  ],
});

export const settingsRoutes: Routes = [
  {
    path: '',
    loadComponent: () => import('./pages/base.component').then(c => c.SettingsPage),
    children: [
      {
        path: CamRoutes.getUrl([ESettingsRoute.settings, ESettingsRoute.teams]),
        loadComponent: () => import('./pages/teams/list/list.component').then(c => c.TeamsPage),
        canActivate: [FeatureGuard],
        data: { feature: 'ticketing', level: 'authorize' },
      },
      {
        path: CamRoutes.getUrl([ESettingsRoute.settings, ESettingsRoute.chift]),
        loadComponent: () => import('./pages/chift/view/view.component').then(c => c.ChiftPage),
        canActivate: [FeatureGuard],
        data: { feature: 'chift', level: 'authorize' },
      },
      {
        path: CamRoutes.getUrl([ESettingsRoute.settings, ESettingsRoute.taskTypes]),
        loadComponent: () => import('./pages/taskTypes/list/list.component').then(c => c.TaskTypesPage),
        canActivate: [FeatureGuard],
        data: { feature: 'ticketing', level: 'authorize' },
      },
      {
        path: CamRoutes.getUrl([ESettingsRoute.settings, ESettingsRoute.view]),
        loadComponent: () => import('./pages/vatCodes/list/list.component').then(c => c.VatCodesPage),
      },
    ],
  },
];
