import { Injectable, TemplateRef } from '@angular/core';

import { map } from 'rxjs';

import { CamRoutes, Menu, MenuBase, MenuIcon, MenuPanel } from '@camelot/menu';
import { Permissions } from '@camelot/user';

import { ESettingsRoute } from '../features/settings/settings.routes';
import { ETasksRoute } from '../features/tasks/tasks.routes';

@Injectable({
  providedIn: 'root',
})
export class AppMenuService {
  constructor() {}

  public getMainMenu() {
    const menu = [
      new MenuIcon({
        key: 'dashboard',
        label: 'menu.main.dashboard',
        link: '/',
        order: 1,
        icon: 'home',
        exact: true,
      }),
      new MenuIcon({
        key: 'taches',
        label: 'menu.main.tasks',
        link: CamRoutes.getAbsoluteUrl([ETasksRoute.tasks]),
        order: 2,
        icon: 'tasks',
        visible$: Permissions.canAccess('ticketing', 'authorize'),
      }),
    ];

    return new Menu({
      elements: menu.sort((a, b) => a.order - b.order),
      direction: 'responsive',
    });
  }

  public getUserMenu(
    accountTemplate: TemplateRef<any>,
    notificationTemplate: TemplateRef<any>,
    notificationCounterTemplate: TemplateRef<any>
  ) {
    const menu = [
      new MenuIcon({
        key: 'settings',
        label: 'menu.user.settings',
        order: 1,
        link: CamRoutes.getAbsoluteUrl([ESettingsRoute.settings, ESettingsRoute.teams]),
        icon: 'settings',
      }),
      new MenuPanel({
        key: 'account',
        label: 'menu.user.account',
        order: 2,
        icon: 'contacts',
        template: accountTemplate,
        endingIcon: 'icon-back',
      }),
      new MenuPanel({
        key: 'notif',
        label: 'menu.user.notifications',
        order: 3,
        icon: 'notifications',
        template: notificationTemplate,
        options: {
          extraTemplate: notificationCounterTemplate,
        },
      }),
    ];

    return new Menu({
      elements: menu.sort((a, b) => a.order - b.order),
      direction: 'responsive',
    });
  }

  public getUserInfosMenu() {
    const menu = [
      new MenuIcon({
        key: 'cgu',
        label: 'menu.user-info.cgu',
        order: 2,
        style: 'dark',
        link: '',
      }),
      new MenuIcon({
        key: 'infos',
        label: 'menu.user-info.cgv',
        order: 3,
        style: 'dark',
        link: '',
      }),
      new MenuIcon({
        key: 'params',
        label: 'menu.user-info.disclaimer',
        order: 4,
        style: 'dark',
        link: '',
      }),
    ];

    return new Menu({
      elements: menu.sort((a, b) => a.order - b.order),
      direction: 'vertical',
    });
  }

  public getSettingsMenu() {
    const menu = [
      new MenuBase({
        key: 'dashboard',
        label: 'menu.settings.home',
        link: '/',
        order: 1,
        exact: true,
      }),
      new MenuBase({
        key: 'teams',
        label: 'menu.settings.teams',
        link: CamRoutes.getAbsoluteUrl([ESettingsRoute.settings, ESettingsRoute.teams]),
        order: 2,
        visible$: Permissions.canAccess('ticketing', 'authorize'),
      }),
      new MenuBase({
        key: 'teams',
        label: 'menu.settings.taskType',
        link: CamRoutes.getAbsoluteUrl([ESettingsRoute.settings, ESettingsRoute.taskTypes]),
        order: 2,
        visible$: Permissions.canAccess('ticketing', 'authorize'),
      }),
      new MenuBase({
        key: 'chift',
        label: 'menu.settings.chift',
        link: CamRoutes.getAbsoluteUrl([ESettingsRoute.settings, ESettingsRoute.chift]),
        order: 3,
        visible$: Permissions.canAccess('chift', 'authorize'),
      }),
    ];

    return new Menu({
      elements: menu.sort((a, b) => a.order - b.order),
      direction: 'responsive',
    });
  }

  public getTasksMenu() {
    const menu = [
      new MenuBase({
        key: 'dashboard',
        label: 'menu.tasks.home',
        link: CamRoutes.getAbsoluteUrl([ETasksRoute.tasks, ETasksRoute.summary]),
        order: 1,
        exact: true,
      }),
      new MenuBase({
        key: 'myTasks',
        label: 'menu.tasks.my-tasks',
        link: CamRoutes.getAbsoluteUrl([ETasksRoute.tasks, ETasksRoute.myTasks]),
        order: 2,
      }),
      new MenuBase({
        key: 'myTeams',
        label: 'menu.tasks.team-tasks',
        link: CamRoutes.getAbsoluteUrl([ETasksRoute.tasks, ETasksRoute.myTeams]),
        order: 3,
      }),
      new MenuBase({
        key: 'allTasks',
        label: 'menu.tasks.all-tasks',
        link: CamRoutes.getAbsoluteUrl([ETasksRoute.tasks, ETasksRoute.allTasks]),
        order: 4,
      }),
      new MenuBase({
        key: 'allTodos',
        label: 'menu.tasks.all-todos',
        link: CamRoutes.getAbsoluteUrl([ETasksRoute.tasks, ETasksRoute.allTodos]),
        order: 5,
      }),
    ];

    return new Menu({
      elements: menu.sort((a, b) => a.order - b.order),
      direction: 'responsive',
    });
  }

  public getAllOrMineMenu(all: (data?: any) => void, mine: (data?: any) => void) {
    const menu = [
      new MenuIcon({
        key: 'all',
        label: 'Tous',
        order: 1,
        callback: all,
        defaultOpen: true,
      }),
      new MenuIcon({
        key: 'mine',
        label: 'Mes fichiers',
        order: 2,
        callback: mine,
        disabled: true,
      }),
    ];

    return new Menu({
      elements: menu.sort((a, b) => a.order - b.order),
      direction: 'responsive',
    });
  }
}
