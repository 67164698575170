@if (this.menu() && this.userMenu()) { @if (this.breakpoints.isDesktop$ | async) {
<cam-main-menu class="menu" [menuMain]="this.menu()" [menuUser]="this.userMenu()"></cam-main-menu>
} @else {
<cam-main-menu class="menu" [menuMain]="this.mergeMenu(this.menu(), this.userMenu())"></cam-main-menu>
} }
<ng-template #notificationCounterTemplate>
  <cam-notification-bullet
    [filters]="{
    isNew: true,
  }"
  ></cam-notification-bullet>
</ng-template>

<ng-template #accountTemplate>
  <div class="my-account">
    <cam-my-account
      [infosMenu]="null"
      [appVersion]="this.appVersion"
      [isEditable]="false"
    ></cam-my-account>
  </div>
</ng-template>

<ng-template #notificationTemplate>
  <div class="notificaitons">
    <div class="m-space-sm">
      <cam-title [level]="3" [isBold]="true">{{ 'base.notification.title' | translate }}</cam-title>
    </div>
    <div class="flex-column g-space-sm">
      <app-notification-list
        type="new"
        title="notifications.menu.new.title"
        ctaTitle="notifications.menu.old.cta"
        (cta)="this.goToNotification()"
      ></app-notification-list>
    </div>
  </div>
</ng-template>
