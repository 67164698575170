import { inject } from '@angular/core';

import { LAZY_SERVICE_TOKEN } from '@camelot/notification';
import { CamUsersService } from '@camelot/user';
import { CamAbstractComponent } from '@camelot/utils';

export abstract class AbstractComponent extends CamAbstractComponent {
  get myId() {
    return this._usersService.currentUser.get()?.id;
  }
  protected _notificationService = inject(LAZY_SERVICE_TOKEN);
  protected _usersService = inject(CamUsersService);

  constructor() {
    super();
  }

  public track(_: any, key: any): any {
    return key;
  }

  public trackById(_: any, item: { id: number }): number {
    return item.id;
  }

  public trackByKey(_: any, item: { key: string }): string {
    return item.key;
  }
}
