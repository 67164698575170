<div class="notification-container flex-column">
  @if (this.title) {
  <cam-text>
    <div class="space-between title-container">
      <div class="flex-row align-center">
        {{ this.title | translate }}
        @if (this.iconName) {
        <div class="icon-container">
          <cam-font-icon [name]="this.iconName"></cam-font-icon>
          <cam-bullet type="notif" size="md">
            {{ this.number }}
          </cam-bullet>
        </div>
        }
      </div>
    </div>
  </cam-text>
  }

  <cam-notification-container
    [routing]="this.routing"
    [filters]="{
      isNew: this.isNew,
      take: this.take
    }"
    (nbChanged)="this.updateNb($event)"
  ></cam-notification-container>

  <div class="mt-space-sm px-space-sm">
    @if (this.ctaTitle) {
    <cam-button size="small" (action)="this.goToCta()">
      {{ this.ctaTitle | translate }}
    </cam-button>
    }
  </div>
</div>
