import { Component, EventEmitter, Input, Output } from '@angular/core';

import { CamIconsModule } from '@camelot/icons';
import { CamRoutes } from '@camelot/menu';
import { CamNotificationModule } from '@camelot/notification';
import { CamUiModule } from '@camelot/ui';
import { CamDirectivePipeModule } from '@camelot/utils';

import { ETasksRoute } from 'src/app/features/tasks/tasks.routes';

import { BaseComponent } from '../../abstract/baseComponent';

@Component({
  selector: 'app-notification-list',
  standalone: true,
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
  imports: [CamUiModule, CamNotificationModule, CamDirectivePipeModule, CamIconsModule],
})
export class NotificationListComponent extends BaseComponent {
  @Input()
  title: string = 'base.notification.title';

  @Input()
  iconName: string | null = null;

  @Input()
  type: 'both' | 'new' | 'old' = 'both';

  @Input()
  take: number | null = null;

  @Input()
  ctaTitle: string | null = null;

  @Output()
  numberOfNotifications = new EventEmitter<number>();

  @Output()
  cta = new EventEmitter<void>();

  public routing = {
    project: (_: unknown) => {},
    invoice: (_: unknown) => {},
    quotationVersion: (_: unknown) => {},
    task: (data: { taskId: string }) => {
      this._router.navigateByUrl(
        CamRoutes.getAbsoluteUrl([ETasksRoute.tasks, ETasksRoute.view], {
          id: data.taskId,
        })
      );
    },
  };

  public number: number = 0;

  get isNew() {
    switch (this.type) {
      case 'new':
        return true;
      case 'old':
        return false;
      default:
        return null;
    }
  }
  constructor() {
    super();
  }

  public goToCta() {
    this.cta.emit();
  }
  public updateNb(nb: number) {
    this.number = nb;
  }
}
