import { AsyncPipe } from '@angular/common';
import { AfterViewChecked, Component, TemplateRef, ViewChild, WritableSignal, inject, signal } from '@angular/core';

import { CamRoutes, Menu } from '@camelot/menu';
import { CamMenuModule } from '@camelot/menu';
import { CamNotificationModule } from '@camelot/notification';
import { CamUiModule } from '@camelot/ui';
import { CamUserModule } from '@camelot/user';
import { CamDirectivePipeModule } from '@camelot/utils';

import { AppMenuService } from 'src/app/services/menus.service';
import { environment } from 'src/environments/environment';

import { BaseComponent } from '../../abstract/baseComponent';
import { NotificationListComponent } from '../../notification/list/notification-list.component';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss'],
  standalone: true,
  imports: [
    CamMenuModule,
    CamNotificationModule,
    CamUserModule,
    CamUiModule,
    CamDirectivePipeModule,
    NotificationListComponent,
    AsyncPipe,
  ],
})
export class MainMenuComponent extends BaseComponent implements AfterViewChecked {
  public menu: WritableSignal<Menu> = signal(new Menu());
  public userMenu: WritableSignal<Menu> = signal(new Menu());

  @ViewChild('accountTemplate') accountTemplate!: TemplateRef<any>;
  @ViewChild('notificationTemplate') notificationTemplate!: TemplateRef<any>;
  @ViewChild('notificationCounterTemplate') notificationCounterTemplate!: TemplateRef<any>;

  private _menuService = inject(AppMenuService);

  get appVersion() {
    return 'Version: ' + `${environment.version}`;
  }

  constructor() {
    super();
    this.menu.set(this._menuService.getMainMenu());
  }

  ngAfterViewChecked() {
    this.userMenu.set(
      this._menuService.getUserMenu(this.accountTemplate, this.notificationTemplate, this.notificationCounterTemplate)
    );
  }

  public goToNotification() {
    this._router.navigateByUrl('notifications');
  }

  public mergeMenu(menu: Menu, userMenu: Menu) {
    return new Menu({
      elements: [...menu.elements, ...userMenu.elements],
      direction: 'responsive',
    });
  }
}
