import { merge } from '@camelot/utils';

import { baseEnvironment } from './base-environment';

export const environment = merge()<typeof baseEnvironment>(baseEnvironment, {
  development: true,
  SERVER_URL: 'https://api-dev.merlinsoftware.be/',
  GRAPHQL_SERVER_CONFIG: {
    url: 'https://api-dev.merlinsoftware.be/',
  },

  DEBUG: true,
  LOCAL: false,

  PWAACTIVE: false,
});
