<cam-layout-page>
  <app-layout-content>
    @if (this.profileName$ | async; as userInfo) {
    <div class="flex-column g-space-md mt-space-sm">
      <div class="flex-row align-center g-space-md">
        <cam-user-logo [userInfo]="userInfo" size="lg"></cam-user-logo>
        <cam-title [level]="3">{{ 'base.home.hello' }}</cam-title>
      </div>

      <div class="ta-c">
        <small>{{ this.version }}</small>
      </div>
    </div>
    }
  </app-layout-content>
  <cam-layout-nav>
    <app-main-menu></app-main-menu>
  </cam-layout-nav>
</cam-layout-page>

<cam-pwa></cam-pwa>
