export const baseEnvironment: {
  development: boolean;
  version: string;

  /**
   * DON'T TOUCH THIS BITCH
   */
  TENANT_ID: number | null;

  DEBUG: boolean;
  DEBUG_LEVEL: number;

  LOCAL: boolean;

  PENDINGREQUESTAPI: number;
  SERVER_URL: string;

  GRAPHQL_SERVER_CONFIG: {
    url: string;
    local_urls: {
      user: string;
    };
  };

  GTM: string;

  APP_NAME: string;
  MAIN_COLOR: string;
  PWAACTIVE: boolean;

  TRANSLATION: {
    DEFAULT: string;
    SUPPORTEDLANGUAGES: string[];
  };

  AUTH0: {
    domain: string;
    clientId: string;
    cacheLocation: 'localstorage';
    // Request this audience at user authentication time
    authorizationParams: {
      audience: string;
      redirect_uri: string;
    };

    // Specify configuration for the interceptor
    httpInterceptor?: {
      allowedList: {
        uri: string;
      }[];
    };
  };

  CHIFT: {
    enable: boolean;
  };
} = {
  development: false,
  version: require('../../package.json').version,

  TENANT_ID: null,
  DEBUG: false,
  LOCAL: false,
  DEBUG_LEVEL: 0,

  PENDINGREQUESTAPI: 5,
  SERVER_URL: '',
  GRAPHQL_SERVER_CONFIG: {
    url: '',
    local_urls: {
      user: '',
    },
  },

  GTM: '',

  APP_NAME: 'Merlin Application',
  MAIN_COLOR: 'white',
  PWAACTIVE: true,

  TRANSLATION: {
    DEFAULT: 'fr',
    SUPPORTEDLANGUAGES: ['fr', 'en', 'nl', 'es'],
  },

  AUTH0: {
    domain: 'merlinsoftware-dev.eu.auth0.com',
    clientId: '0NLd1vuhsVQ6qQZkxDCdeeZ70rA3kfwZ',
    cacheLocation: 'localstorage',
    authorizationParams: {
      audience: 'https://api-dev.merlinsoftware.be',
      redirect_uri: window.location.origin,
    },
    httpInterceptor: {
      allowedList: [
        {
          uri: 'https://api-dev.merlinsoftware.be/*', // 'http://192.168.111.72:5000/api/tristan/*',
        },
        {
          uri: 'https://localhost:4200/*',
        },
      ],
    },
  },

  CHIFT: {
    enable: false,
  },
};
