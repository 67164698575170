import { Component } from '@angular/core';

import { AbstractComponent } from './abstractComponent';

@Component({ template: '' })
export abstract class BaseComponent extends AbstractComponent {
  constructor() {
    super();
  }
}
