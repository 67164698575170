import { Routes } from '@angular/router';

import { CamMainRoute, CamRoutes } from '@camelot/menu';
import { AuthGuard, FeatureGuard, LoginRedirectComponent } from '@camelot/user';

import { ESettingsRoute, settingsRoutes } from './features/settings/settings.routes';
import { ETasksRoute, tasksRoutes } from './features/tasks/tasks.routes';

export const routes: Routes = [
  {
    path: '',
    redirectTo: CamRoutes.getUrl([ETasksRoute.tasks]),
    pathMatch: 'full',
  },
  {
    path: CamRoutes.getUrl([CamMainRoute.REDIRECT]),
    loadComponent: () => import('./features/base/redirect/redirect.component').then(c => c.RedirectPage),
    canActivate: [AuthGuard],
  },
  {
    path: CamRoutes.getUrl([CamMainRoute.NOTIFICATIONS]),
    loadComponent: () => import('./features/base/notification/notifications.component').then(c => c.NotificationsPage),
    canActivate: [AuthGuard],
  },
  {
    path: CamRoutes.getUrl([CamMainRoute.USERLOGIN]),
    component: LoginRedirectComponent,
  },
  {
    path: CamRoutes.getUrl([ETasksRoute.tasks]),
    children: tasksRoutes,
    canActivate: [AuthGuard, FeatureGuard],
    data: { feature: 'ticketing', level: 'authorize' },
  },
  {
    path: CamRoutes.getUrl([ESettingsRoute.settings]),
    children: settingsRoutes,
    canActivate: [AuthGuard],
  },
];
