import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { CamNotificationModule } from '@camelot/notification';
import { CAM_AUTH_TOKEN } from '@camelot/user';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [RouterOutlet, CamNotificationModule],
})
export class AppComponent {
  title = 'merlin';

  private _authService = inject(CAM_AUTH_TOKEN);

  constructor() {
    this._authService.load();
  }
}
